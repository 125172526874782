import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

const ImageBox = ({ id, src, isSelected, onClick }) => (
  <Box
    sx={{
      position: "relative",
      width: "100%",
      height: "200px", // Height of the box larger than the image size
      border: 2,
      borderRadius: 2,
      cursor: "pointer",
      transition: "all 0.3s",
      borderColor: isSelected ? "primary.main" : "grey.300",
      boxShadow: isSelected ? "0 0 0 7px #1976d2" : "none", // Ring effect for selected
      "&:hover": {
        borderColor: "grey.400",
      },
    }}
    onClick={() => onClick(id)}
    role="button"
    aria-pressed={isSelected}
    tabIndex={0}
  >
    <img
      src={src}
      alt={`Image ${id + 1}`}
      style={{
        objectFit: "cover", // Ensures the image covers the area of the box
        width: "100%",
        height: "100%", // Make the image fill the box
        borderRadius: "8px", // Rounded corners for the image
      }}
    />
  </Box>
);

export default function GridSelectorModal({ images, onClick }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (id) => {
    setSelectedImage(id);
  };

  const handleOkClick = () => {
    if (selectedImage !== null) {
      onClick(selectedImage);
    }
    setIsOpen(false);
  };

  return (
    <>
      <Button variant="outlined" onClick={() => setIsOpen(true)}>
        Background Selector
      </Button>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="image-selector-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="image-selector-dialog-title">Background Selector</DialogTitle>
        <DialogContent>
          <Grid sx={{
            marginTop: "10px",
          }} container spacing={2} >
            {images.map((src, i) => (
              <Grid item xs={4} key={i}>
                <ImageBox
                  id={i}
                  src={src}
                  isSelected={selectedImage === i}
                  onClick={handleImageClick}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleOkClick} disabled={selectedImage === null}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
